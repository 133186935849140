import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TariffCodeByCompanyProvider } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import TariffCodeByCompanyMaintenance from "presentation/view/section/TariffCodeByCompany/TariffCodeByCompanyMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TariffCodeByCompanyContMain = () => {
    return <ANAInfoWrapper permission={Permission.TARIFF_CODE_BY_COMPANY_MAINTENANCE}>
        <MessageBarWrapper>
            <TariffCodeByCompanyProvider>
                <GridStyles/>
                <TariffCodeByCompanyMaintenance/>
            </TariffCodeByCompanyProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TariffCodeByCompanyContMain;