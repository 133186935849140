import { EMPTY_TARIFF_CODE_BY_COMPANY_MODEL } from "presentation/model/TariffCodeByCompany/TariffCodeByCompanyModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: TariffCodeByCompanyProvider,
    useTracked: useTariffCodeByCompanyTracked
} = createContainer(() => useState(EMPTY_TARIFF_CODE_BY_COMPANY_MODEL), {concurrentMode: true});
export { TariffCodeByCompanyProvider, useTariffCodeByCompanyTracked };

