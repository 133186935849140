import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CompanyEntity } from "domain/entity/Company/CompanyEntity";
import { CompanyTariffCodeEntity } from "domain/entity/TariffCode/CompanyTariffCodeEntity";
import _ from "lodash";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { CompanyTariffCodeRepository } from "./CompanyTariffCodeRepo";

export const CompanyTariffCodeRepoImpl = (): CompanyTariffCodeRepository => {
    const url = '/v1/companyTariffCode';
    const findCmpTarCodeUrl = '/v1/companyTariffCodeByCompanyId';
    const cmpUrl = '/v1/searchTariffCodeCompanyList';


    const saveMultipleTariffCompany = async(companines: string[]): Promise<ResponseEntity> => {
        const saveMultiUrl =  '/v1/saveMultipleTariffCompany';
        return await axiosPostData(tariffAxiosInstance, `${saveMultiUrl}`, companines);
    }

    const deleteMultipleTariffCompanines = async(companines: CompanyEntity[]): Promise<ResponseEntity> => {
        const delMultiUrl =  '/v1/deleteMultipleTariffCompany';
        return await axiosPostData(tariffAxiosInstance, `${delMultiUrl}`, companines);
    }

    /**
     * Get all company with tariff code
     * @returns CompanyEntity[]
     */
    const findTariffCodeCompany = async() : Promise<CompanyEntity[]> => {
        return await axiosGetData(tariffAxiosInstance, cmpUrl, []).then(res => {
            return _.sortBy(res.data, ["companyCode"]);
        }).catch(err => {
            return [];
        });
    }

    /**
     * Get the company taiff code by company id
     * @returns CompanyTariffCodeEntity[]
     */
    const findCompanyTariffCodeByCompanyId = async(companyId: number) : Promise<CompanyTariffCodeEntity[]> => {
        return await axiosGetData(tariffAxiosInstance, findCmpTarCodeUrl, [companyId.toString()]).then(res => {
            return _.sortBy(res.data, ["seq"]);
        }).catch(err => {
            return [];
        });
    }

    const replaceCompanyTariffCode = async(replacedEntity: CompanyTariffCodeEntity): Promise<ResponseEntity> => {
        return await axiosPutData(tariffAxiosInstance, `${url}`, replacedEntity);
    }

    const onApply = async(updatedRows: CompanyTariffCodeEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/companyTariffCodeApply"
        return await axiosPostData(tariffAxiosInstance, `${applyUrl}`, updatedRows);
    }

    return {
        saveMultipleTariffCompany: saveMultipleTariffCompany,
        deleteMultipleTariffCompanines: deleteMultipleTariffCompanines,
        findTariffCodeCompany: findTariffCodeCompany,
        findCompanyTariffCodeByCompanyId: findCompanyTariffCodeByCompanyId,
        replaceCompanyTariffCode:replaceCompanyTariffCode,
        onApply: onApply,
    }
}