import * as yup from 'yup';


export const commonTariffCodeByCompanyValidation: { [x: string]: any; } = {
  companines: yup.array().required("Missing"),
};

export const commonTariffCodeByCompanyDetailValidation: { [x: string]: any; } = {
  tariffSeq1: yup.string().required("Missing"),
  tariffSeq2: yup.string().required("Missing"),
  tariffSeq3: yup.string().required("Missing"),
};



export const createTariffCodeByCompanyValidation: { [x: string]: any; } = {
  ...commonTariffCodeByCompanyValidation,
};

export const createTariffCodeByCompanyDetailValidation: { [x: string]: any; } = {
  ...commonTariffCodeByCompanyDetailValidation,
};

export const createTariffCodeByCompanyValidationSchema = yup.object().shape(createTariffCodeByCompanyValidation);
export const createTariffCodeByCompanyDetailValidationSchema = yup.object().shape(createTariffCodeByCompanyDetailValidation);

