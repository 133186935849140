import { TariffCodeConstant, tariffCodeMainRequiredFieldList } from "presentation/constant/TariffCode/TariffCodeConstant"
import { useTariffCodeByCompanyVM } from "presentation/hook/TariffCodeByCompany/useTariffCodeByCompanyVM"
import { useMessageBarVM } from "presentation/hook/useMessageBar"
import { useTariffCodeByCompanyTracked } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider"
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer"
import { NbisCommonField } from "presentation/view/components/NbisCommonField"
import { useCallback, useMemo, useState } from "react"
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core"
import { SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled"

export const TariffCodeByCompanyDetailFormPanel = () => {
    const [tariffCodeByCompanyState] = useTariffCodeByCompanyTracked();
    const tariffCodeByCompanyVM = useTariffCodeByCompanyVM();
    const messageBarVM = useMessageBarVM();
    const {currentSelectedRow, detailState, dynamicOptions} = tariffCodeByCompanyState;
    const { isAdd, isEditable, isRead, isSaveClicked, allFormState,currentSelectItem, editingItem} = detailState;
    const TARIFF_CODE_CONSTANT = TariffCodeConstant.Table;
    const [isLoading, setIsLoading] = useState(false);

    const currentEntity = isRead?currentSelectItem : editingItem;

    const memoParentTariffCodeOptions = useMemo(() => {  
        return currentEntity?.parentTariffType  
            ? dynamicOptions.parentTariffCodeDropdownOptions[currentEntity?.parentTariffType]  
            : [];  
    }, [currentEntity?.parentTariffType, dynamicOptions.parentTariffCodeDropdownOptions]);

    
    
    const handleCancelClick = () => {
        tariffCodeByCompanyVM.onDetailEditCancel();
    }

    const handleSaveClick = useCallback(async ()  => {
        setIsLoading(true);
        tariffCodeByCompanyVM.onDetailSaveClicked();
        const res = await tariffCodeByCompanyVM.onDetailSave(editingItem);
        setIsLoading(false);
        if(res){
            messageBarVM.showWarining(res["warningMessage"]);
        }else{
            tariffCodeByCompanyVM.onSearchCompanyTariffCode(currentSelectedRow)
        }

    },[currentSelectedRow, editingItem, messageBarVM, tariffCodeByCompanyVM])

    const memoSeq = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEntity?.seq?.toString() || ''}
                //fieldValue={currentEntity?.seq?.toString()}
                fieldLabel={TARIFF_CODE_CONSTANT.SEQ}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'seq'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />        
        </div>
    , [TARIFF_CODE_CONSTANT.SEQ, currentEntity?.seq, isSaveClicked])   


    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffType || ''}
                fieldValue={currentEntity?.tariffType}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />       
        </div>
        , [allFormState, currentEntity?.tariffType, TARIFF_CODE_CONSTANT.TARIFF_TYPE, isSaveClicked])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffCode || ''}
                fieldValue={currentEntity?.tariffCode}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCode'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />      
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_CODE, allFormState, currentEntity?.tariffCode, isSaveClicked])

        const memoTarCodeDesc = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffCodeDesc || ''}
                    fieldValue={currentEntity?.tariffCodeDesc}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE_DESC}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffCodeDesc'}
                    isShowMissingError={true}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={()=>{}} />    
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_CODE_DESC, allFormState, currentEntity?.tariffCodeDesc, isSaveClicked])

        const memoTarCodeDescOther = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffCodeDescOther || ''}
                    fieldValue={currentEntity?.tariffCodeDescOther}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE_DESCOTHER}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffCodeDescOther'}
                    isShowMissingError={false}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={()=>{}} />        
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_CODE_DESCOTHER, currentEntity?.tariffCodeDescOther, isSaveClicked])

        const memoTarGpIdOne = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffGroupId1 || ''}
                    fieldValue={currentEntity?.tariffGroupId1}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_ONE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffGroupId1'}
                    isShowMissingError={false}
                    isTextFieldCapitalize={true}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeByCompanyVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />   
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_ONE, currentEntity?.tariffGroupId1, isRead, isSaveClicked, tariffCodeByCompanyVM])

        const memoTarGpIdTwo = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffGroupId2 || ''}
                    fieldValue={currentEntity?.tariffGroupId2}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_TWO}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffGroupId2'}
                    isShowMissingError={false}
                    isTextFieldCapitalize={true}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeByCompanyVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_TWO, currentEntity?.tariffGroupId2, isRead, isSaveClicked, tariffCodeByCompanyVM])    

        const memoTarGpIdThree = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffGroupId3 || ''}
                    fieldValue={currentEntity?.tariffGroupId3}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_THREE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffGroupId3'}
                    isShowMissingError={false}
                    isTextFieldCapitalize={true}
                    type="number"
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeByCompanyVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_THREE, currentEntity?.tariffGroupId3, isRead, isSaveClicked, tariffCodeByCompanyVM])

        const memoTarSeqOne = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffSeq1?.toString() || ''}
                    fieldValue={currentEntity?.tariffSeq1?.toString()}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_ONE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffSeq1'}
                    isShowMissingError={true}
                    maxLength={60}
                    requiredFieldList={tariffCodeMainRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeByCompanyVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_ONE, allFormState, currentEntity?.tariffSeq1, isRead, isSaveClicked, tariffCodeByCompanyVM])

        const memoTarSeqTwo = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffSeq2?.toString() || ''}
                    fieldValue={currentEntity?.tariffSeq2?.toString()}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_TWO}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffSeq2'}
                    isShowMissingError={true}
                    maxLength={60}
                    requiredFieldList={tariffCodeMainRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeByCompanyVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_TWO, allFormState, currentEntity?.tariffSeq2, isRead, isSaveClicked, tariffCodeByCompanyVM])
        
        const memoTarSeqThree = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffSeq3?.toString() || ''}
                    fieldValue={currentEntity?.tariffSeq3?.toString()}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_THREE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffSeq3'}
                    isShowMissingError={true}
                    maxLength={60}
                    requiredFieldList={tariffCodeMainRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeByCompanyVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_THREE, allFormState, currentEntity?.tariffSeq3, isRead, isSaveClicked, tariffCodeByCompanyVM])
                
        


        const memoParentTarType = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parentTariffType || ''}
                fieldValue={currentEntity?.parentTariffType??''}
                fieldLabel={TARIFF_CODE_CONSTANT.PARENT_TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'parentTariffType'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />    
            </div>
            , [TARIFF_CODE_CONSTANT.PARENT_TARIFF_TYPE, currentEntity?.parentTariffType, isSaveClicked])
    
        const memoParentTarCode = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parentTariffCode || ''}
                fieldValue={currentEntity?.parentTariffCode || ''}
                fieldLabel={TARIFF_CODE_CONSTANT.PARENT_TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'parentTariffCode'}
                isShowMissingError={false}
                maxLength={60}
                options={memoParentTariffCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffCodeByCompanyVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> 
            </div>
            , [TARIFF_CODE_CONSTANT.PARENT_TARIFF_CODE, currentEntity?.parentTariffCode, isRead, isSaveClicked, memoParentTariffCodeOptions, tariffCodeByCompanyVM])


    return (
        <div className='side-form-content-wrapper' style={{position:'relative', height:"100vh-128px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            <SidebarTitle>{currentEntity.tariffType + '/' + currentEntity.tariffCode}</SidebarTitle>
                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
           
            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                    <CriteriaItemContainer>
                            {memoSeq}
                        </CriteriaItemContainer>
                        
                        <CriteriaItemContainer>
                            {memoTarType}
                            {memoTarCode}
                        </CriteriaItemContainer>
                        

                        <CriteriaItemContainer>
                            {memoParentTarType}
                            {memoParentTarCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTarCodeDesc}
                            {memoTarCodeDescOther}
                        </CriteriaItemContainer>                        

                        <CriteriaItemContainer>
                            {memoTarGpIdOne}
                            {memoTarGpIdTwo}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTarGpIdThree}
                            {memoTarSeqOne}
                        </CriteriaItemContainer>
                        
                        <CriteriaItemContainer>
                            {memoTarSeqTwo}
                            {memoTarSeqThree}
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead?'Close':'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            {!isRead&&<HPHButton label={isAdd?'Add':"Update"} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

