
export interface CompanyTariffCodeEntity {
    id: number | null,
    versionIdentifier: {
        version?: string
    },
    tariffType: string,
    tariffCode: string,
    creationType: "CompanyTariffCodeEntity",
    seq: number | null,
    parentTariffType?: string | null,
    parentTariffCode?: string | null,
    tariffCodeDesc?: string | null,
    tariffCodeDescOther?: string | null,
    includeTputInd?: "Y" | "N",
    percentage?: number | null,
    tariffSchemeId?: number | null,
    tarSchemeCode: string | null,
    activeInd: "Y" | "N",
    tariffGroupId1: string | null,
    tariffGroupId2: string | null,
    tariffGroupId3: string | null,
    tariffSeq1: number | null,
    tariffSeq2: number | null,
    tariffSeq3: number | null,
    tarNature: string | null,
    tariffNatureList: string[] | null,
    tariffCompValIdList: number[] | null,
    tempSeq: number | null,

    companyId: number | null,
    refStdTariffCodeId: number | null,
    

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_TARIFF_CODE_ENTITY : CompanyTariffCodeEntity = {
    id: null,
    versionIdentifier: {},
    tariffType: "",
    tariffCode: "",
    creationType: "CompanyTariffCodeEntity",
    seq: null,
    activeInd: "Y",
    tariffGroupId1: null,
    tariffGroupId2: null,
    tariffGroupId3: null,
    tariffSeq1: null,
    tariffSeq2: null,
    tariffSeq3: null,
    tarSchemeCode: null,
    tarNature: null,
    tariffNatureList: [],
    tariffCompValIdList: [],
    percentage: 100,
    includeTputInd: 'Y',
    parentTariffType: null,
    parentTariffCode: null,
    tempSeq: null,
    companyId: null,
    refStdTariffCodeId: null
}